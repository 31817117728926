import epic from "./media/epicPre.png";
import fim from "./media/fimPre.png";
import red from "./media/redPre.png";
import task2 from "./media/task2Pre.png";
import candy from "./media/candyPre.png";
// import arc from "./media/arcadePre.png";
import ws from "./media/wsPre.png";
import ttt from "./media/tttPre.png";
import task1 from "./media/task1Pre.png";
import temp from "./media/tempsPre.png";
import calci from "./media/calciPre.png";
import playa from "./media/playaPre.png";
import drn from "./media/runePre.png";
import delphi from "./media/delphiPre.png";
import real from "./media/realPre.png";
import snek from "./media/sneakPre.png";

const baroqueWorks = [
  {
    id: 11,
    img: playa,
    name: "PlayerOrbit",
    disc: "This is the playerorbit's central website on which i've worked at my internship. Working on this website built my foundation for working in corporate environment. Here we provide coaching services for everyone and lead them on the rignt path towards becoming grand master. ",
    link: "https://www.playerorbit.com/",
  },
  {
    id: 11,
    img: delphi,
    name: "Delphi Analytics",
    disc: "Delphi Analytics is also a project which I've worked at my internship. This project gave me valuable insights about dealing with clients and fullfill their needs. Delphi Analytics is a data analytics service provider, working with major brands and partners.",
    link: "https://www.delphianalytics.in/",
  },
  {
    id: 11,
    img: real,
    name: "Real CRM",
    disc: "Real crm is my first major fullstack project I've picked up which had me working with various new technologies as per client needs and requirements. unfortunately this project cannot be shown by me to anyone due to being under nda. ",
    link: "/",
  },
  {
    id: 11,
    img: snek,
    name: "Sneak peek",
    disc: "This is a fullstack project made with mern stack in intention with selling shoes online market. This is a fully functional website, from login to secure payment thus feature compelete and client ready. ",
    link: "https://sneak-peek-fn.vercel.app/",
  },
  {
    id: 12,
    img: drn,
    name: "Rune Chat",
    disc: "This is a chat app made with react in addition to firebase. This project taught me a lot about how messaging works and gave me valuable insights to firebase as all the messages are stored and retrived from there.",
    link: "https://dark-rune-network.vercel.app/",
  },
  {
    id: 7,
    img: ws,
    name: "World Seeker",
    disc: "This is a full stack booking website made in mern stack. here we can book our holiday package to some quite intresting places. Working on this i had quite a learning experience form react to aws.",
    link: "https://world-seeker.vercel.app/",
  },
  {
    id: 8,
    img: ttt,
    name: "Tic Tac Toe",
    disc: "This is a tic tac toe game which can be played on any browser at any time with a custom built ai which has gone rogue. ",
    link: "https://r3tr0lastknight.github.io/TIC-TAC-TOE/",
  },
  {
    id: 3,
    img: red,
    name: "Factions",
    disc: "This is a project depicts various military factions of rainbow six. while making this website i gained valuable expreience about redux.",
    link: "https://r3tr0lastknight.github.io/Rainbow-Six-Factions/",
  },
  {
    id: 6,
    img: task2,
    name: "Taskmaster v2",
    disc: "This is the second itteration of the taskmaster web-app, made completely in react. the visuals have been completely overhauled from previous build.",
    link: "https://r3tr0lastknight.github.io/Taskmaster_v2/",
  },
  {
    id: 9,
    img: calci,
    name: "Calcuator",
    disc: "Here is a custom calculator which perform every calcuation with enhanced precision with just a mere click.",
    link: "https://r3tr0lastknight.github.io/Simple-Calculator/",
  },
  {
    id: 4,
    img: candy,
    name: "Museum of Candy",
    disc: "This is a bootstrap project about a website the shocases candies placed in my Museum made with bootstrap.",
    link: "https://r3tr0lastknight.github.io/Museum-of-Candy/",
  },
  {
    id: 5,
    img: task1,
    name: "Taskmaster v1",
    disc: "This is the first itteration of taskmaster which was made purely html, css & javascript.",
    link: "https://r3tr0lastknight.github.io/TaskMaster_v1/",
  },
  {
    id: 2,
    img: fim,
    name: "EliteBuild",
    disc: "This is a website for a coatching institute that conducts preperation of jee and neet. crafted and made fully responsive with pure html, css and javascript.",
    link: "https://r3tr0lastknight.github.io/FIM-homepage/",
  },
  {
    id: 10,
    img: temp,
    name: "Temps",
    disc: "This is a temprature conversion web-app which tranceduces temprature value between celsius, fahrenheit and kelvin",
    link: "https://r3tr0lastknight.github.io/TempConverter-TEMPS/",
  },

  {
    id: 1,
    img: epic,
    name: "Epic Games",
    disc: "This is a replica of The epic games website made with pure html css and javascript. it includes game listings and other information.",
    link: "https://r3tr0lastknight.github.io/EPIC-GAMES-CLONE/",
  },
];

export default baroqueWorks;
